import React from "react"

import styles from "./content.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

const ContentPage8 = () => {
  return (
    <AccountGuard>
      <div className={`${styles.header}`}>
        <h2>Technical Official Introductory</h2>
      </div>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sectionHeader}`}>
          <h3>Section 08</h3>
          <h1>Continual Professional Development</h1>
        </div>
      </div>

      <div className={`${styles.contentWrapper}`}>
        <ul className={`${styles.content}`}>
          <li>
            <p>
              Central to the mission of the iF3 Technical Officials Committee is
              providing development opportunities in order to build a culture of
              excellence and ubiquity in practice.
            </p>

            <p>
              Having highly capable Technical Officials not only ensures
              accurate event outcomes but instills confidence in the sport’s
              overall legitimacy. This is critical to future acceptance into the
              Olympic system as well as to building commercial appeal for the
              sport.
            </p>

            <p>
              IF3 is committed to developing and promoting Technical Officials
              in order to build a worldwide network of highly-skilled Technical
              Officials. Future training courses and other practical experience
              opportunities will be made available to those interested and
              capable of handling additional responsibility within the iF3. This
              include course instructors, Head Judges, and other support or
              administrative roles.
            </p>
          </li>
        </ul>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.previous}`}>
            <a href="/courses/toi/content/7">
              <i
                className={`fas fa-arrow-alt-circle-left uk-margin-small-right`}
              />
              Previous
            </a>
          </div>
          <div className={`${styles.next}`}>
            <a href="/courses/toi/content/9">
              Next
              <i
                className={`fas fa-arrow-alt-circle-right uk-margin-small-left`}
              />
            </a>
          </div>
        </div>
      </div>
    </AccountGuard>
  )
}

export default ContentPage8
